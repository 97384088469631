import {
    quotationUrlApi,
    exceptionCodeResponse,
    validateFileResponse,
	validateResponse
} from './urls';

export const showFile = async (token, data) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		'Content-Type': 'application/json',
	};

	const body = JSON.stringify(data);
	const options = { method: 'POST', headers, body }

	fetch(`${quotationUrlApi}file/getFile`, options)
		.then(response => validateFileResponse(response))
		.then(blob => {
			window.open(URL.createObjectURL(blob), '_blank');
		})
		.catch((error) => {
			throw exceptionCodeResponse(error);
		});
};

export const getImgTypeBlob = async (token, data) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		'Content-Type': 'application/json',
	};

	const body = JSON.stringify(data);
	const options = { method: 'POST', headers, body }

	return await fetch(`${quotationUrlApi}file/getFile`, options)
		.then(response => validateFileResponse(response))
		.then(blob => URL.createObjectURL(blob))
		.catch((error) => {
			throw exceptionCodeResponse(error);
		});
};

export const getRepositoryActive = async (token) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		'Content-Type': 'application/json',
	};

	const options = { method: 'GET', headers }

	return await fetch(`${quotationUrlApi}repositories/active`, options)
		.then(response => validateResponse(response))
		.then(response => response.repositoryCode)
		.catch((error) => {
			throw exceptionCodeResponse(error);
		});
}