import React, { useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import { Link } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEventsByUserId,
  getNotificationsByUserId
} from '../../actions/userEventsActions';
import { menuGroupOpenedAction } from '../../actions/menuOptionsActions';
import { setUserEventListeners } from '../../sevices/notificationService';
import { EVENTS_MODULES } from '../../utils/urls';

import { getRepositoryActive } from '../../utils/getFile';
import { getImg } from '../../utils/fileManagement';

import MessageShow from '../messages/messageShow';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronRight } from '@material-ui/icons';
import { Grid, IconButton, Divider, Drawer, Hidden } from '@material-ui/core';
import BarMenu from './BarMenu';
import SideMenu from './SideMenu';
import SideMenuClose from './sideMenuClose';

import Logo from '../../assets/images/iConnectance_mini_logo_2.png';
import MenuBottom from './MenuBottom';
import OrangeMenu from './OrangeMenu';

const useStyles = makeStyles((theme) => ({
  toolbarIcon: {
    display: 'flex',
    padding: '0 8px',
    height: 110,
    ...theme.mixins.toolbar,
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%'
    }
  },
  barIcon: {
    transition: 'all .5s ease-out'
  },
  barIconRotate: {
    transform: 'rotate(-180deg)'
  },
  drawer: {
    width: 220,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerPaper: {
    zIndex: '1000 !important',
    borderRight: 'none !important',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: 240,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    zIndex: '1000 !important',
    overflowX: 'hidden',
    position: 'relative',
    borderRight: 'none !important',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  backgroundDrawer: {
    backgroundColor: 'white',
    maxWidth: 75
  },
  shrink: {
    // width: 72,
    width: 58,
    backgroundColor: 'white'
  }
}));

const ContainerMenu = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const userEvents = useSelector((state) => state.userEvents);

  const [menuOpen, setMenuOpen] = useState(false);
  const [menuOpenSmall, setMenuOpenSmall] = useState(false);
  const [optionSelected, setOptionSelected] = useState('');
  const [repositoryCode, setRepositoryCode] = useState('');
  const [logoImage, setLogoImage] = useState('');
  const config = useSelector((state) => state.userConfig.data);

  const handleDrawerMenu = () => {
    dispatch(menuGroupOpenedAction(menuOpen && ''));
    setMenuOpen(!menuOpen);
  };

  const handleDrawerMenuSmall = () => {
    setMenuOpenSmall(!menuOpenSmall);
  };

  useEffect(() => {
    initUserEvents();

    //react-hooks/exhaustive-deps
  }, []);

  const initUserEvents = async () => {
    await dispatch(getEventsByUserId(token, EVENTS_MODULES));
    await dispatch(getNotificationsByUserId(token));
  };

  useEffect(() => {
    if (userEvents.data.length > 0) {
      initSockets();
    }
  }, [userEvents]);

  const initSockets = async () => {
    setUserEventListeners(
      userEvents.io,
      userEvents.data,
      token,
      dispatch,
      config.logoImage ? config.logoImage : Logo
    );
  };

  const handleGetOptionMenu = (name) => {
    setOptionSelected(name);
  };

  const initRepository = useCallback(async () => {
    try {
      const repository = await getRepositoryActive(token);
      setRepositoryCode(repository);
    } catch (error) {
      console.error('Error fetching repository:', error);
    }
  }, [token]);

  useEffect(() => {
    initRepository();
  }, [initRepository]);

  const initImg = async (path, token, repositoryCode) => {
    const { urlBlob } = await getImg(path, token, repositoryCode);
    setLogoImage(urlBlob)
  }

  useEffect(() => {
    if (repositoryCode !== '' && logoImage === '' && config.logoImage) {
      initImg(config.logoImage, token, repositoryCode);
    }
  }, [logoImage, repositoryCode, config])

  return (
    <>
      <BarMenu
        menuOpen={menuOpen}
        handleDrawerMenu={handleDrawerMenu}
        notifications={userEvents.notifications}
        token={token}
      />
      <MenuBottom />
      <Hidden xsDown>
        {menuOpen ? (
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerPaper]: menuOpen,
              [classes.drawerPaperClose]: !menuOpen,
            })}
            classes={{
              paper: clsx({
                [classes.drawerPaper]: menuOpen,
                [classes.drawerPaperClose]: !menuOpen,
              }),
            }}
            open={menuOpen}
          >
            <div className={classes.toolbarIcon}>
              <div>
                <Link to="/">
                  <img
                    src={logoImage}
                    alt="iConnectance"
                    style={{ 'max-width': 240, 'max-height': 110 }}
                  />
                </Link>
              </div>
            </div>
            <Divider />
            <Grid
              container
              item
              justifyContent="center"
              style={{ maxWidth: 72 }}
            >
              <IconButton
                onClick={handleDrawerMenu}
                style={{ marginTop: 10 }}
              >
                <ChevronRight
                  fontSize="large"
                  style={{
                    color: config.theme
                      ? config.theme.secondary.variant5
                      : "rgb(175, 138, 252)",
                  }}
                  className={clsx(classes.barIcon, {
                    [classes.barIconRotate]: menuOpen,
                  })}
                />
              </IconButton>
            </Grid>
            <SideMenu handleGetOptionMenu={handleGetOptionMenu} />
          </Drawer>
        ) : (
          <Grid className={classes.shrink}>
            <div className={classes.toolbarIcon}>
              <div>
                <Link to="/dashboard">
                  <img
                    src={logoImage}
                    alt="iConnectance"
                    style={{ width: 36, height: 36 }}
                  />
                </Link>
              </div>
            </div>
            <Divider />
            <Grid
              container
              item
              justifyContent="center"
              style={{ maxWidth: 72 }}
            >
              <IconButton
                onClick={handleDrawerMenu}
                style={{ marginTop: 10 }}
              >
                <ChevronRight
                  fontSize="large"
                  style={{
                    color: config.theme
                      ? config.theme.secondary.variant5
                      : "rgb(175, 138, 252)",
                  }}
                  className={clsx(classes.barIcon, {
                    [classes.barIconRotate]: menuOpen,
                  })}
                />
              </IconButton>
            </Grid>
            <SideMenuClose
              menuOpen={menuOpenSmall}
              handleDrawerMenu={() => handleDrawerMenuSmall()}
              optionSelected={optionSelected}
            />
          </Grid>
        )}
      </Hidden>
      <MessageShow />
      <OrangeMenu token={token} />
    </>
  );
};

export default ContainerMenu;
